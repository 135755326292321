import { Account, Avatars, Client, Databases, Storage } from 'appwrite'

export const appwriteConfig = {
  //* this keeps projectID safe
  projectId: import.meta.env.VITE_APPWRITE_PROJECT_ID,
  url: import.meta.env.VITE_APPWRITE_URL,
  databaseId: import.meta.env.VITE_APPWRITE_DATABASE_ID,
  storageId: import.meta.env.VITE_APPWRITE_STORAGE_ID,
  lyricsId: import.meta.env.VITE_APPWRITE_LYRICS_COLLECTION_ID,
  songInfoId: import.meta.env.VITE_APPWRITE_SONG_INFO_COLLECTION_ID,
}

export const client = new Client()

client.setProject(appwriteConfig.projectId)
client.setEndpoint(appwriteConfig.url)

export const account = new Account(client)
export const databases = new Databases(client)
export const storage = new Storage(client)
export const avatars = new Avatars(client)
